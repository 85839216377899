import React, { useState } from "react";
import MobileNav from "./mobileNav";
import HamburgerButton from "./hamburgerButton";

const MobileNavContainer = ({ navLinks }) => {
  const [hasMobileNav, setMobileNav] = useState(false);
  const handleClick = () => setMobileNav(!hasMobileNav);

  return (
    <>
      <MobileNav
        handleClick={handleClick}
        navLinks={navLinks}
        hasMobileNav={hasMobileNav}
      />
      <HamburgerButton handleClick={handleClick} />
    </>
  );
};

export default MobileNavContainer;
