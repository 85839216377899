import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MobilePrimaryButton } from "../buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const backgroundVariants = {
  open: { y: 0 },
  closed: {
    y: "-100%",
    transition: {
      delay: 0.1,
      type: "spring",
      damping: 18,
    },
  },
};

const NavVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
  closed: {
    opacity: 0,
    y: -80,
  },
};

const MobileNav = ({ handleClick, navLinks, hasMobileNav }) => {
  return (
    <AnimatePresence>
      {hasMobileNav && (
        <nav>
          <motion.div
            className="absolute inset-0 z-20 h-screen bg-primary-orange"
            variants={backgroundVariants}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ type: "spring", stiffness: 150, damping: 30 }}
          />
          <motion.ul
            className="absolute inset-0 z-40 flex flex-col justify-center w-10/12 h-screen max-w-sm mx-auto align-middle"
            variants={NavVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <li>
              <MobilePrimaryButton
                handleClick={handleClick}
                btnLabel="order now"
                url="/#locations"
              />
            </li>
            {renderNavLinks(handleClick, navLinks)}
          </motion.ul>
          <motion.button
            className="absolute top-0 right-0 z-40 px-4 pt-2 text-3xl text-center text-white focus:outline-none"
            onClick={handleClick}
            variants={NavVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <FontAwesomeIcon icon={faTimes} />
          </motion.button>
        </nav>
      )}
    </AnimatePresence>
  );
};

const renderNavLinks = (handleClick, navLinks) => {
  return navLinks.map(navLink => {
    return (
      <li className="mb-6 text-center" key={navLink.title}>
        <a
          href={navLink.href}
          className="font-black tracking-wide text-white uppercase font-lato"
          onClick={handleClick}
        >
          {navLink.title}
        </a>
      </li>
    );
  });
};

export default MobileNav;
