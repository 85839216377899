import React from "react";
import SocialIcons from "./iconsSocial";

const Footer = () => {
  return (
    <footer className="flex flex-col p-4 bg-gray-900 lg:flex-row lg:justify-between lg:items-center">
      <div className="flex justify-between w-full max-w-sm px-10 mx-auto my-2 lg:mx-1 lg:max-w-xs">
        <SocialIcons />
      </div>
      <div className="text-sm text-center text-white font-montserrat">
        <p className="lg:text-right">
          © {new Date().getFullYear()} Otto's Tacos Holding Co, LLC
        </p>
        <p className="lg:text-right">
          <a href="https://google.com">Privacy Policy</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
