import React from "react";
import { motion } from "framer-motion";

export const PrimaryButton = ({ btnLabel, url }) => {
  return (
    <motion.a
      className="block w-5/6 max-w-sm py-5 mx-auto font-black tracking-wide text-center text-white uppercase transition duration-300 ease-in-out rounded-lg font-lato bg-primary-orange hover:shadow-2xl"
      href={url}
      target=""
      rel="noreferrer"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
    >
      {btnLabel}
    </motion.a>
  );
};

export const CardPrimaryButton = ({ btnLabel, url }) => {
  return (
    <motion.a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="block w-full py-3 mx-auto text-sm font-black tracking-wide text-center text-white uppercase transition duration-200 ease-in-out transform rounded-lg font-lato bg-primary-orange hover:bg-primary-orange hover:shadow-lg"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
    >
      {btnLabel}
    </motion.a>
  );
};

export const CardSecondaryButton = ({ btnLabel, url }) => {
  return (
    <motion.a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="block w-full py-3 mx-auto text-sm font-black tracking-wide text-center uppercase transition duration-200 ease-in-out transform border rounded-lg font-lato hover:bg-secondary-orange hover:shadow-lg lg:hover:scale-105 text-secondary-orange border-secondary-orange hover:text-white"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
    >
      {btnLabel}
    </motion.a>
  );
};

export const MobilePrimaryButton = ({ btnLabel, url, handleClick }) => {
  return (
    <motion.a
      href={url}
      target=""
      rel="noreferrer"
      className="block w-full py-5 mx-auto my-6 font-black tracking-wide text-center uppercase bg-white rounded-lg font-lato text-primary-orange hover:shadow-lg"
      onClick={handleClick}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
    >
      {btnLabel}
    </motion.a>
  );
};

export const NavPrimaryButton = ({ btnLabel, url }) => {
  return (
    <motion.a
      href={url}
      target=""
      rel="noreferrer"
      className="block w-auto px-10 py-2 mx-auto text-sm font-black tracking-wide text-center text-white uppercase transition duration-300 ease-in-out rounded-lg font-lato bg-primary-orange hover:shadow-lg"
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.02 }}
    >
      {btnLabel}
    </motion.a>
  );
};
