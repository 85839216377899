import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const HamburgerButton = ({ handleClick }) => {
  return (
    <button
      className="px-4 text-3xl text-center text-primary-orange focus:outline-none lg:hidden"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faBars} />
    </button>
  );
};

export default HamburgerButton;
