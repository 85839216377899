import React from "react";
import { motion } from "framer-motion";
import ottosTacosLogo from "../images/ottos-tacos-logo-small.svg";
import navLinks from "../data/navLinks";
import DesktopNav from "./nav/desktopNav";
import MobileNavContainer from "./nav/mobileNavContainer";

const Header = () => {
  return (
    <motion.header
      className="fixed z-50 flex justify-between w-full h-16 pl-4 shadow-md bg-ottos-grey"
      initial={{ y: "-100%" }}
      animate={{ y: 0 }}
      transition={{ delay: 0.8, type: "spring", stiffness: 100, damping: 20 }}
    >
      <a href="/#home" className="flex w-40 align-middle md:py-2 justify-left">
        <img
          src={ottosTacosLogo}
          alt="Otto's Tacos Logo"
          className="md:h-full"
        />
      </a>
      <DesktopNav navLinks={navLinks} />
      <MobileNavContainer navLinks={navLinks} />
    </motion.header>
  );
};

export default Header;
