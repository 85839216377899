import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const socialAccounts = [
  {
    name: "Instagram",
    icon: faInstagram,
    iconColor: "text-instagram-pink",
    url: "https://instagram.com/ottostacos",
  },
  {
    name: "Facebook",
    icon: faFacebook,
    iconColor: "text-facebook-blue",
    url: "https://facebook.com/ottostacos",
  },
  {
    name: "Twitter",
    icon: faTwitter,
    iconColor: "text-twitter-blue",
    url: "https://twitter.com/ottostacos",
  },
];

const SocialIcons = () => {
  return socialAccounts.map(socialAct => {
    return (
      <a
        key={socialAct.name}
        href={socialAct.url}
        target="_blank"
        rel="noreferrer"
        className={`${socialAct.iconColor} text-4xl`}
      >
        <FontAwesomeIcon icon={socialAct.icon} />
      </a>
    );
  });
};

export default SocialIcons;
