import React from "react";
import { NavPrimaryButton } from "../buttons";

const DesktopNav = ({ navLinks }) => {
  return (
    <nav className="hidden lg:flex">
      <ul className="flex items-center pr-4">
        {renderNavLinks(navLinks)}
        <li>
          <NavPrimaryButton
            btnLabel="order now"
            // url="https://order.ottostacos.com/"
            url="/#locations"
          />
        </li>
      </ul>
    </nav>
  );
};

const renderNavLinks = navLinks => {
  return navLinks.map(navLink => {
    return (
      <li className="pr-10 text-center" key={navLink.title}>
        <a
          href={navLink.href}
          className="text-sm font-black tracking-wide uppercase font-lato text-ottos-dark-grey"
        >
          {navLink.title}
        </a>
      </li>
    );
  });
};

export default DesktopNav;
