const navLinks = [
  {
    title: "menu",
    href: "/#menu",
  },
  // {
  //   title: "catering",
  //   href: "/#catering",
  // },
  {
    title: "locations",
    href: "/#locations",
  },
  {
    title: "about",
    href: "/#about",
  },
];

export default navLinks;
